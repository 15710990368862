import { useGtm } from "@gtm-support/vue-gtm";
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("page:finish", () => {
    const gtm = useGtm();
    const route = useRoute();
    setTimeout(() => {
      const pageUrl = route.fullPath;
      gtm?.push({
        event: "screen_view",
        path: pageUrl,
      });
    }, 500);
  });
});
