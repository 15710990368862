export const routes = {
  homeUrl: "/",
  // ESPACE CANDIDAT
  candidatUrl: {
    index: "/travail-extra-cdi-interim",
    hidfLyon: "travailler-a-lyon",
    lpJo: "emploi-jeux-olympiques-2024",
    lpInterim: "interim-restauration-paris",
    lpHotellerie: "emploi-hotellerie",
  },
  // ESPACE RECRUTEUR
  recruteurUrl: {
    index: "/recrutement",
    lpEtabLyon: "restauration-vente-lyon",
    demandeDeDemo: "demandez-une-demo",
    bu: {
      cafeRestaurant: "renfort-extra-restauration",
      hotellerie: "renfort-extra-hotellerie",
      restaurationCollective: "renfort-restauration-collective",
      traiteurEvenementiel: "renfort-extra-traiteur-evenementiel",
      commerceDeBouche: "renfort-extra-commerce",
      logistiqueVente: "renfort-extra-logistique-vente",
    },
  },
  // BLOG
  blogHomeUrl: "/blog",
  // FAQ
  faqUrl: {
    home: "/faq",
    candidats: "/faq/candidats",
    recruteurs: "/faq/recruteurs",
  },
  // PARTENAIRES
  partnersUrl: {
    index: "/nos-partenaires",
    candidats: "/nos-partenaires/candidats",
    recruteurs: "/nos-partenaires/recruteurs",
  },
  // MEDIA PRESSE
  mediaPressUrl: "/media-presse",
  // SIMULATEUR CA AE
  simulatorCaAe: "/simulation-revenus-auto-entrepreneur",
  // JOBS
  jobsUrl: {
    index: "https://jobs.extracadabra.com/",
    paris: "https://jobs.extracadabra.com/paris",
    lyon: "https://jobs.extracadabra.com/lyon",
    extra: "https://jobs.extracadabra.com/missions-extra",
    ae: "https://jobs.extracadabra.com/missions-extra?c=extra",
    cdi: "https://jobs.extracadabra.com/recherche?c=cdi",
    saisonnier: "https://jobs.extracadabra.com/recherche?c=saisonnier",
    interim: "https://jobs.extracadabra.com/recherche?c=interim",
    hotel: "https://jobs.extracadabra.com/hotellerie-restauration",
    logistic: "https://jobs.extracadabra.com/vente-logistique",
    chefDeCuisine: "https://jobs.extracadabra.com/chef-de-cuisine",
    employePolyvalent: "https://jobs.extracadabra.com/employe-polyvalent",
    serveur: "https://jobs.extracadabra.com/emploi-serveur",
    chefDeRang: "https://jobs.extracadabra.com/chef-de-rang",
    patissier: "https://jobs.extracadabra.com/patissier",
    barman: "https://jobs.extracadabra.com/poste-barman",
    maitreDhotel: "https://jobs.extracadabra.com/maitre-d-hotel",
    runner: "https://jobs.extracadabra.com/runner",
    chefDePartie: "https://jobs.extracadabra.com/chef-de-partie",
    vendeurEnBoulangerie: "https://jobs.extracadabra.com/recherche?q=74",
    receptionniste: "https://jobs.extracadabra.com/recherche?q=46",
    nightAuditor: "https://jobs.extracadabra.com/recherche?q=55",
    manutentionnaire: "https://jobs.extracadabra.com/recherche?q=53,117",
    secondDeCuisine: "https://jobs.extracadabra.com/recherche?q=12",
    restauration: "https://jobs.extracadabra.com/emploi-restauration",
    evenementiel:
      "https://jobs.extracadabra.com/recherche?q=13&q=14&q=2&q=43&q=26&q=32&q=100&q=3&q=25&q=12&q=8&q=11&q=49&q=94&q=67&q=5&q=28&q=9&q=64&q=42&q=101&q=53&q=95&q=96&q=98&q=38&q=75&q=99&q=87&q=74&q=62&q=92&q=10&q=118&q=33&q=79&q=40&q=93&q=7&q=97&q=41&q=45&q=111&q=1",
  },
  // ETABLISSEMENT WEB APP
  etabWebAppUrl: {
    baseUrl: "https://etablissement.extracadabra.com/",
    landing: "#/auth/landing",
    signup: "#/auth/signup",
    login: "#/auth/login",
  },

  // CANDIDAT WEB APP
  candidatWebAppUrl: {
    baseUrl: "https://candidat.extracadabra.com/",
    signup: "#/creer-un-compte",
    login: "#/se-connecter",
  },
  cgu: "/cgu",
  confidentiality: "/confidentiality",
  faqByTypeUrl: (type: "candidats" | "recruteurs") => `/faq/${type}`,
  faqItem: (type: "candidats" | "recruteurs", slug: string) => `/faq/${type}/${slug}`,
};
